<template>
  <v-container fluid>
    <v-data-iterator
      :items="items"
      :items-per-page.sync="itemsPerPage"
      :page.sync="page"
      :search="search"
      hide-default-footer
      loading-text="Cargando..."
      no-data-text="No hay datos para mostrar"
      no-results-text="No se encontraron resultados"
      :sort-by="sortBy"
      :sort-desc="true"
    >
      <template v-slot:header>
        <v-toolbar dark rounded>
          <v-text-field
            v-model="search"
            clearable
            flat
            solo-inverted
            hide-details
            prepend-inner-icon="mdi-magnify"
            label="Buscar"
          ></v-text-field>
          <v-btn text class="ml-4" :to="{ name: 'New Service' }"> Nuevo </v-btn>
        </v-toolbar>
        <v-progress-linear
          indeterminate
          rounded
          color="yellow darken-3"
          :active="loading"
        ></v-progress-linear>
        <v-expansion-panels class="mt-3 mb-3">
          <v-expansion-panel>
            <v-expansion-panel-header>
              Seleccionar mes
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-date-picker
                v-model="month"
                :show-current="true"
                type="month"
                full-width
                locale="es"
                :disabled="loading"
              ></v-date-picker>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </template>

      <template v-slot:default="props">
        <v-card>
          <v-simple-table>
            <thead>
              <tr>
                <th class="text-left">Cliente</th>
                <th class="text-left">Patente</th>
                <th class="text-left">Fecha</th>
              </tr>
            </thead>
            <tbody>
              <v-menu
                absolute
                offset-y
                dark
                style="max-width: 600px"
                v-for="item in props.items"
                :key="item.patent"
              >
                <template v-slot:activator="{ on, attrs }">
                  <tr v-bind="attrs" v-on="on">
                    <td>{{ item.client }}</td>
                    <td>{{ item.patent }}</td>
                    <td>{{ formatDate(item.date) }}</td>
                  </tr>
                </template>

                <v-list>
                  <v-list-item
                    link
                    @click.stop="
                      serviceSelected = item;
                      editService();
                    "
                  >
                    <v-list-item-title>Editar</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    link
                    @click.stop="
                      openDeleteDialog = true;
                      serviceSelected = item;
                    "
                  >
                    <v-list-item-title>Eliminar</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>

              <v-dialog dark max-width="290" v-model="openDeleteDialog">
                <v-card>
                  <v-card-title class="headline"> ¿Estás seguro? </v-card-title>

                  <v-card-text>
                    Eliminar servicio con patente:
                    <b>{{ serviceSelected.patent }}</b
                    >.
                    <br />
                    Esta acción no se puede deshacer.
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="red darken-1"
                      text
                      @click="openDeleteDialog = false"
                    >
                      Cancelar
                    </v-btn>
                    <v-btn
                      color="green darken-1"
                      text
                      @click="
                        openDeleteDialog = false;
                        deleteService();
                      "
                    >
                      Continuar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </tbody>
          </v-simple-table>
        </v-card>
      </template>

      <template v-slot:footer>
        <v-toolbar
          rounded
          class="mt-3 mb-2"
          align="center"
          dark
          justify="center"
        >
          <span class="white--text pagination d-none d-sm-block"
            >Por página</span
          >
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                dark
                plain
                small
                class="pagination"
                v-bind="attrs"
                v-on="on"
              >
                {{ itemsPerPage }}
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(number, index) in itemsPerPageArray"
                :key="index"
                @click="updateItemsPerPage(number)"
              >
                <v-list-item-title>{{ number }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-spacer></v-spacer>

          <span class="mr-2 white--text pagination">
            <span class="d-none d-sm-inline">Página </span>
            {{ page }} de {{ numberOfPages }}
          </span>
          <v-btn icon small @click="formerPage">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn icon small class="mr-1" @click="nextPage">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-toolbar>
      </template>
    </v-data-iterator>
  </v-container>
</template>

<script>
export default {
  name: "Table",
  data() {
    return {
      itemsPerPageArray: [5, 10, 25, 50],
      search: "",
      page: 1,
      itemsPerPage: 10,
      month: new Date().toISOString().substring(0, 7),
      openDeleteDialog: false,
      serviceSelected: {},
      sortBy: "date",
    };
  },
  created() {
    this.getServices();
  },
  computed: {
    numberOfPages() {
      return Math.ceil(this.items.length / this.itemsPerPage);
    },
    filteredKeys() {
      return this.keys.filter((key) => key !== "Name");
    },
    items() {
      return this.$store.state.services;
    },
    loading() {
      return this.$store.state.loadingServices;
    },
  },
  methods: {
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1;
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1;
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number;
    },
    getServices() {
      // TODO: se debería verificar de mejor manera la variable
      // TODO: se pueden agregar reglas en firestore para que solo se puedan listar pasando la variable month
      if (!!this.month) this.$store.dispatch("getServices", this.month);
    },
    deleteService() {
      this.$store.dispatch("deleteService", this.serviceSelected.id);
    },
    editService() {
      this.$router.push("/admin/editar/" + this.serviceSelected.id);
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
  },
  watch: {
    month: function (val, oldVal) {
      if (val != oldVal) this.getServices();
    },
  },
};
</script>

<style scoped>
table {
  table-layout: fixed !important;
}
.actions {
  max-width: 100px;
}
.pagination {
  font-size: 14px !important;
}
</style>